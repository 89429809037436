<template>
    <ul
        aria-label="Accordion Control Group Buttons"
        class="bull-accordion list-unstyled"
    >
        <li v-for="item in internalisedItems" :key="item.id">
            <button
                :aria-controls="`control-${item.id}`"
                :id="`accordion-control-${item.id}`"
                :aria-expanded="(item.id === activeItemId).toString()"
                @click="
                    ;(activeItemId = activeItemId === item.id ? null : item.id),
                        $emit('activeItemIdChanged', item.id)
                "
                class="bull-accordion__title"
                :class="
                    activeItemId === item.id
                        ? 'bull-accordion__title--active'
                        : ''
                "
                @mouseover="cursorStore.setCursorType('link-hover')"
                @mouseleave="cursorStore.setCursorType('default')"
            >
                <h3 class="bull-accordion__title-text">
                    {{ item.title }}
                </h3>
                <div class="bull-accordion__title-icon">
                    <FontIcon
                        size="medium"
                        icon="arrow"
                        :color="
                            item.id === activeItemId ? 'lime-green' : 'white'
                        "
                    />
                </div>
            </button>
            <transition
                name="accordion"
                @enter="start"
                @after-enter="end"
                @before-leave="start"
                @after-leave="end"
            >
                <div
                    :aria-hidden="(item.id !== activeItemId).toString()"
                    :id="`control-${item.id}`"
                    v-show="item.active"
                >
                    <div
                        class="bull__accordion-content"
                        :class="{
                            ['bull__accordion-content--active']: item.active,
                        }"
                    >
                        <slot :name="item.id" />
                    </div>
                </div>
            </transition>
        </li>
    </ul>
</template>

<script>
export default defineNuxtComponent({
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    emits: ['activeItemIdChanged'],

    setup() {
        const cursorStore = useCursorStore()

        return { cursorStore }
    },

    data() {
        return {
            activeItemId: null,
        }
    },

    computed: {
        internalisedItems() {
            return this.items.map((item) => {
                return { ...item, active: item.id === this.activeItemId }
            })
        },
    },

    methods: {
        start(el) {
            el.style.height = el.scrollHeight + 'px'
        },

        end(el) {
            el.style.height = ''
        },
    },
})
</script>

<style lang="scss" scoped>
.bull-accordion {
    li {
        margin-bottom: 0;
    }
}

.bull-accordion__title {
    appearance: none;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--white);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.75rem;
    margin-bottom: 1.5rem;
    text-align: left;
    color: var(--white);
    transition: all 0.4s 0s ease;

    h3 {
        @include body-lg;

        font-weight: var(--font-weight-semibold);
    }

    @include large-up {
        h3 {
            @include body-xl;
        }
    }

    @include small-only {
        align-items: center;
    }

    &:hover {
        .bull-accordion__title-icon {
            opacity: 1;
        }
    }
}

.bull-accordion__title-icon {
    opacity: 0.4;
    transform: rotate(45deg);
    transition: all 0.4s 0s ease;
}

.bull-accordion__title--active {
    border-bottom-color: var(--page-theme-base-colour, var(--lime-green));
    color: var(--page-theme-base-colour, var(--lime-green));

    .bull-accordion__title-icon {
        opacity: 1;
        transform: rotate(135deg);

        .font-icon {
            --font-icon-colour: var(
                --page-theme-base-colour,
                var(--lime-green)
            );
        }
    }
}

.bull__accordion-content {
    @include body-md;

    padding-top: 0.75rem;
    padding-bottom: 3.75rem;

    @include medium-up {
        padding-top: 0.75rem;
        padding-bottom: 3.25rem;
    }
}

.bull__accordion-content--active {
    color: var(--page-theme-base-colour, var(--lime-green));
}
</style>

<style lang="scss">
.bull__accordion-content * {
    @include body-md;
}

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition:
        height 0.5s ease,
        opacity 0.5s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}
</style>
