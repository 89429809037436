<template>
    <section :id="sliceId" class="AccordionContentBlock">
        <FillTextEffect
            :id="sliceId"
            :trigger-container="'#' + sliceId"
            bottom-position="500px"
            v-if="props.slice.primary.title.length"
            class="AccordionContentBlock__title"
        >
            <PrismicRichText :field="props.slice.primary.title" />
        </FillTextEffect>
        <div class="AccordionContentBlock__accordion-wrapper">
            <div class="AccordionContentBlock__images">
                <CustomLink
                    v-for="({ id, image }, index) in transformedItems"
                    class="AccordionContentBlock__image-container"
                    :key="id"
                    :class="[
                        {
                            'AccordionContentBlock__image-container--active':
                                !activeItemId && index === 0
                                    ? true
                                    : id === activeItemId,
                            'AccordionContentBlock__image-container--no-image':
                                !image.url,
                        },
                    ]"
                    :to="buildInternalLink(slice.primary)"
                    :href="slice.primary.external_link.url"
                    :cursor-text="slice.primary.link_text"
                    cursor-type="view"
                >
                    <SmartPicture
                        class="AccordionContentBlock__image"
                        :small-image="image.small.url"
                        :medium-image="image.medium.url"
                        :large-image="image.url"
                    />
                </CustomLink>
                <div class="AccordionContentBlock__button-icon">
                    <IconButton
                        :to="buildInternalLink(slice.primary)"
                        :href="slice.primary.external_link.url"
                        :text="slice.primary.link_text ?? 'View'"
                        size="x-small"
                        filled="white"
                    />
                </div>
            </div>
            <BullAccordion
                class="AccordionContentBlock__accordion"
                :items="transformedItems"
                @activeItemIdChanged="
                    activeItemId =
                        activeItemId === $event ? activeItemId : $event
                "
            >
                <template
                    v-for="item in transformedItems"
                    :key="item.title"
                    #[item.id]
                >
                    <PrismicRichText
                        :field="item.description"
                        class="AccordionContentBlock__dropdown-content"
                    />
                </template>
            </BullAccordion>
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.AccordionContentBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const activeItemId = ref(null)
const sliceId = props.slice.id.replace('$', '-') + '-accordion'

const transformedItems = computed(() => {
    return props.slice.items.reduce((previousValue, item, index) => {
        previousValue.push({
            id: sliceId + '-' + index,
            title: item.accordion_title,
            description: item.accordion_content,
            image: item.image,
        })

        return previousValue
    }, [])
})

function buildInternalLink(item) {
    let url

    if (item.internal_link.url) {
        url = item.internal_link.url
    } else {
        return ''
    }

    if (item.prefilter_to_brand.uid) {
        url += '?brand=' + item.prefilter_to_brand.uid

        if (item.prefilter_to_capability.uid) {
            url += '&capability=' + item.prefilter_to_capability.uid
        }
    } else if (item.prefilter_to_capability.uid) {
        url += '?capability=' + item.prefilter_to_capability.uid
    }

    return url
}
</script>

<style lang="scss" scoped>
.TextHero + .AccordionContentBlock {
    margin-top: 3rem;

    @include medium-up {
        margin-top: 8rem;
    }
}

.AccordionContentBlock {
    grid-column: 2 / span 22;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 48rem;
    margin-top: 8rem;

    @include medium-up {
        margin-top: 11.25rem;
    }

    @include large-up {
        max-width: 93.75rem;
    }
}

.AccordionContentBlock__wrapper {
    @include large-up {
        display: grid;
        grid-template-columns: minmax(424px, 1fr) minmax(320px, 900px);
        column-gap: 5rem;
    }
}

.AccordionContentBlock__title {
    display: block;
    max-width: 36.75rem;
    margin-bottom: 2.5rem;
}

.AccordionContentBlock__pretitle {
    display: block;
    margin-bottom: 1.5rem;
}

.AccordionContentBlock__images {
    display: block;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    perspective: 100px;
}

.AccordionContentBlock__image-container {
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: translate3d(0, -20px, -5px);
    transition:
        opacity 0.3s ease,
        transform 0.5s ease;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--page-theme-base-colour, var(--lime-green));
        transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
    }
}

.AccordionContentBlock__image-container--active {
    z-index: 1;
    opacity: 1;
    transform: translate3d(0, 0px, 0px);
}

.AccordionContentBlock__image-container--no-image {
    height: 100%;

    .AccordionContentBlock__image {
        height: 100%;
    }
}

.AccordionContentBlock__image {
    transform: translate(0, 0);
}

.AccordionContentBlock__image-container:before,
.AccordionContentBlock__image {
    transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.AccordionContentBlock__image-container:hover {
    &:before {
        transform: translate(-4px, -4px);
    }

    .AccordionContentBlock__image {
        transform: translate(4px, 4px);
    }
}

.AccordionContentBlock__accordion-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    width: 100%;

    @include large-up {
        column-gap: 5rem;
        flex-wrap: nowrap;
    }
}

.AccordionContentBlock__accordion {
    width: 100%;
    margin-top: 3.5rem;

    @include large-up {
        margin-top: 0;
        width: 80%;
    }
}

.AccordionContentBlock__button-icon {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    display: none;

    @include touchscreen {
        display: block;
    }
}
</style>

<style lang="scss">
.AccordionContentBlock__title h2 {
    @include heading-2;
}

.AccordionContentBlock__dropdown-content {
    a {
        color: var(--lime-green);
    }
}
</style>
